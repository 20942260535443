import { NavLink } from 'react-router-dom';
import { IFooterItem } from './types';
import i18n from '../../i18n';

const menuItems: IFooterItem[] = [
  {
    id: 1,
    title: i18n.t("pages:investments-and-development.header"),
    url: '/investments-and-development',
  },
  {
    id: 2,
    title: i18n.t('pages:gastronomy.header'),
    url: '/gastronomy',
  },
];

const FooterItems = () => (
  <>
    {menuItems.map((item: IFooterItem, index: number) => (
      <li key={index} className="berg-components-menu-item">
        <>
          <NavLink to={item.url}>
            <span>{item.title}</span>
          </NavLink>
          <span aria-hidden className="berg-components-menu-item-bold-template">
            {item.title}
          </span>
        </>
      </li>
    ))}
  </>
);

export default FooterItems;
