import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Components from '../../components';
import Cookies from '../../components/Cookies/Cookies';
import Loader from '../../components/Loader/Loader';
import visibleOnRelease from '../../config/visibleOnRelease';
import Context from '../../context';
import Hooks from '../../hooks';
import { fetchAnnouncements } from '../../modules/location/announcements/operations';
import { fetchBillboards } from '../../modules/location/billboards/operations';
import { fetchSettings } from '../../modules/location/settings/operations';
import { fetchWeather } from '../../modules/location/weather/operations';
import Billboard from './Billboard';
import ScrollToTop from './ScrollToTop';
import './Start.scss';
import 'react-toastify/dist/ReactToastify.css';
import Icons from '../../assets/icons';
// import { Season } from '../../config/globalVariables';
// import { SeasonContext } from '../../context/SeasonContext';

const Start = () => {
  const dispatch = Hooks.useAppDispatch();
  // const { season } = useContext(SeasonContext);
  const loc = useLocation().pathname;
  const [location, setLocation] = useState(loc);

  useEffect(() => {
    setLocation(loc);
  }, [location, loc]);

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchAnnouncements());
    dispatch(fetchBillboards());

    const handleFetchWeather = () => {
      dispatch(fetchWeather());
    };

    handleFetchWeather();

    const weatherIntervalIndex = setInterval(handleFetchWeather, 300_000);

    return () => {
      clearInterval(weatherIntervalIndex);
    };
  }, [dispatch]);

  return (
    <>
      <Context.SeasonTheme>
        {/* <header id="header">
          <div className="berg-pages-start__header-placeholder-summer"></div>
        </header> */}
        <Context.Navigation>
          <div id="sub-header"></div>
          <main>
            <Outlet />
          </main>
        </Context.Navigation>
        {/* <footer>
          <Components.Footer />
        </footer> */}
        {location !== '/cart' &&
          location !== '/cart/order-data' &&
          location !== '/cart/summary' &&
          location !== '/payment/status' &&
          location !== '/map' && <Components.BasketStickyCTA />}
        {visibleOnRelease.planStay && <Components.StickyCTA />}
        <Loader />
      </Context.SeasonTheme>
      <ScrollToTop />
      <Billboard />
      <Cookies />
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={({ closeToast }) => (
          <button onClick={closeToast} className="berg-styles-snackbar-close">
            <Icons.Xmark />
          </button>
        )}
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Start;
