/* eslint-disable max-lines */
import {
  getBasketCurrency,
  getBasketItemsByType,
  getBasketTotal,
  // getBasketTotalAtLocation,
} from '../../modules/basket/selectors';
import './CartManagement.scss';
import { useNavigate } from 'react-router-dom';
import Components from '../../components';
import Hooks from '../../hooks';
import BergPageContentWrapper from '../../components/Storybook/BergPageContentWrapper/BergPageContentWrapper';
import { useTranslation } from 'react-i18next';
import useCartSteps from '../../hooks/useCartSteps';
import { IColumn } from '../../components/OrderTable/OrderTable';
import moment from 'moment';
import calculatePrice from '../../utils/calculatePrice';
import CartOperations from './cart-management/CartOperations';
import { BasketItemType } from '../../modules/basket/models';

const CartManagement = () => {
  const { t } = useTranslation(['pages', 'common', 'components']);
  const navigate = useNavigate();
  const skiPasses = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.SkiPass)
  );
  const accommodations = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Accommodation)
  );
  const parking = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Parking)
  );
  const basketTotal = Hooks.useAppSelector((x) => getBasketTotal(x.basket));

  const basketCurrency = Hooks.useAppSelector((x) =>
    getBasketCurrency(x.basket)
  );

  const { steps, currentStep } = useCartSteps(0);

  const skiPassTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row.item.name}</span>
          {row.dataCarrierIds?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.validityDate).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const accommodationTable: IColumn[] = [
    {
      header: t('pages:accommodation.cart_table.name_header'),
      rows: (row) => row.item.name,
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_room'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.extra_bed'),
      rows: (row) => row.additionalGuestsCount || 0,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_header'),
      rows: (row) => row.daysCount,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => {
        return (
          moment(row.startDate).format('DD.MM.YYYY') +
          ' - ' +
          moment(row.endDate).format('DD.MM.YYYY')
        );
      },
      thClassName: 'visible@l',
      tdClassName: 'w36@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(
          row.price,
          row.daysCount,
          row.quantity,
          row.additionalGuestPrice,
          row.additionalGuestsCount
        )} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w20@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const parkingTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row?.item?.name}</span>
          {row?.licencePlates?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.parking_additional_info')}
              items={row.licencePlates}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  console.log(parking);

  return (
    <BergPageContentWrapper header={t('pages:cart.header')}>
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      {skiPasses?.length > 0 && (
        <>
          <p className="payment-status-label">
            {t('common:table_label.passes')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={skiPasses}
            columns={skiPassTable}
          />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
        </>
      )}
      {accommodations?.length > 0 && (
        <>
          <p className="payment-status-label">
            {t('common:table_label.accommodation')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={accommodations}
            columns={accommodationTable}
          />
        </>
      )}
      {parking?.length > 0 && (
        <>
          <p className="payment-status-label">
            {t('common:table_label.parking')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={parking}
            columns={parkingTable}
          />
        </>
      )}
      <Components.BergDevider color="ligth-grey"></Components.BergDevider>
      <Components.BergStack
        direction="column"
        alignItems="end"
        justifyContent="space-between"
        className="cart-products__buttons"
      >
        <div>
          <div className="order-summary-footer">
            <div className="order-summary-footer__item-header--bold">
              {t('components:order_summary_header.amount_label')}
            </div>
            <div className="order-summary-footer__item-description--bold">
              {basketTotal} {basketCurrency}
            </div>
          </div>
        </div>
        <div>
          <Components.BergButton
            color="primary"
            onClick={() => navigate('/parking')}
            label={t('pages:cart.continue_shopping')}
          ></Components.BergButton>
          {basketTotal === 0 ? (
            <Components.BergButton
              color="gray-400"
              onClick={() => navigate('/cart/order-data')}
              label={t('pages:cart.goto_order_data_button_text')}
              disabled
            ></Components.BergButton>
          ) : (
            <Components.BergButton
              color="red"
              onClick={() => navigate('/cart/order-data')}
              label={t('pages:cart.goto_order_data_button_text')}
            ></Components.BergButton>
          )}
        </div>
      </Components.BergStack>
    </BergPageContentWrapper>
  );
};

export default CartManagement;
