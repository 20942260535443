interface ISiteSettings {
  instagramUrl: string;
  facebookUrl: string;
}

const siteSettings: ISiteSettings = {
  instagramUrl: '',
  facebookUrl: 'https://www.facebook.com/aquaparkoravice.sk/',
};

export default siteSettings;
