/* eslint-disable max-lines */
import BergPageContentWrapper from '../../../components/Storybook/BergPageContentWrapper/BergPageContentWrapper';
import { Trans, useTranslation } from 'react-i18next';
import Components from '../../../components';
import calculatePrice from '../../../utils/calculatePrice';
import moment from 'moment';
import { IColumn } from '../../../components/OrderTable/OrderTable';
import './CartSummary.scss';
import CollapseList from '../../../components/CollapseList/CollapseList';
import { useNavigate } from 'react-router-dom';
import Hooks from '../../../hooks';
import {
  getBasketCompanyData,
  getBasketContactData,
  getBasketCurrency,
  getBasketItemsByType,
  getBasketTotal,
} from '../../../modules/basket/selectors';
import { BasketItemType } from '../../../modules/basket/models';
import useCartSteps from '../../../hooks/useCartSteps';
import { createOrder } from '../../../modules/order/operations';
import regulationSalesEn from '../../../assets/regulations/sales_regulations_EN.pdf';
import { useState } from 'react';
import Tooltip from '../../../components/Tooltip/Tooltip';

const CartSummary = () => {
  const { t } = useTranslation(['pages', 'common', 'components']);
  const navigate = useNavigate();
  const skiPasses = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.SkiPass)
  );
  const accommodations = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Accommodation)
  );
  const parking = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Parking)
  );
  const basketTotal = Hooks.useAppSelector((x) => getBasketTotal(x.basket));
  const basketCurrency = Hooks.useAppSelector((x) =>
    getBasketCurrency(x.basket)
  );
  const formData = Hooks.useAppSelector((s) => getBasketContactData(s.basket));
  const formCompanyData = Hooks.useAppSelector((s) =>
    getBasketCompanyData(s.basket)
  );
  const { steps, currentStep } = useCartSteps(2);
  const dispatch = Hooks.useAppDispatch();
  const handleSubmit = () => {
    dispatch(createOrder())
      .unwrap()
      .then((result) => {
        if (result) {
          navigate(`/payment/status?OrderID=${result.id}`);
        }
      });
  };
  // const [language] = useState(i18n.resolvedLanguage);
  // const regulationsSales = (lang: string) => {
  //   switch (lang) {
  //     case 'en':
  //       return regulationSalesEn;
  //     case 'sk':
  //       return regulationSalesSk;
  //     default:
  //       return regulationSalesPl;
  //   }
  // };
  const [readRegulations, setReadRegulations] = useState<boolean>(false);
  const handleReadRegulations = () => {
    setReadRegulations((value) => !value);
  };
  const skiPassTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row.item.name}</span>
          {row.dataCarrierIds.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.validityDate).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const accommodationTable: IColumn[] = [
    {
      header: t('pages:accommodation.cart_table.name_header'),
      rows: (row) => row.item.name,
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_room'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.extra_bed'),
      rows: (row) => row.additionalGuestsCount || 0,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_header'),
      rows: (row) => row.daysCount,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => {
        return (
          moment(row.startDate).format('DD.MM.YYYY') +
          ' - ' +
          moment(row.endDate).format('DD.MM.YYYY')
        );
      },
      thClassName: 'visible@l',
      tdClassName: 'w36@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(
          row.price,
          row.daysCount,
          row.quantity,
          row.additionalGuestPrice,
          row.additionalGuestsCount
        )} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const parkingTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row?.item?.name}</span>
          {row?.licencePlates?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.parking_additional_info')}
              items={row.licencePlates}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  console.log(parking);

  return (
    <BergPageContentWrapper header={t('pages:cart_summary.header')}>
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      <Components.OrderContactData
        contactData={formData}
        companyData={formCompanyData}
      ></Components.OrderContactData>
      <Components.BergDevider color="ligth-grey"></Components.BergDevider>
      {skiPasses?.length > 0 && (
        <>
          <p className="payment-status-label">
            {t('common:table_label.passes')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={skiPasses}
            columns={skiPassTable}
          />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
        </>
      )}
      {accommodations?.length > 0 && (
        <>
          <p className="payment-status-label">
            {t('common:table_label.accommodation')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={accommodations}
            columns={accommodationTable}
          />
        </>
      )}
      {parking?.length > 0 && (
        <>
          <p className="payment-status-label">
            {t('common:table_label.parking')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={parking}
            columns={parkingTable}
          />
        </>
      )}
      <Components.BergDevider color="ligth-grey"></Components.BergDevider>
      <Components.BergStack
        direction="row"
        justifyContent="space-between"
        className="cart-summary-all-footer"
      >
        <Components.BergStack
          direction="column"
          spacing={8}
          // alignSelf="end"
          // mb={8}
          className="cart-summary-footer"
        >
          <Components.BergCheckbox
            name="readRegulations"
            onValueChange={handleReadRegulations}
            label={
              <Trans
                ns="pages"
                i18nKey="cart_summary.checkbox"
                components={{
                  a: (
                    <a
                      // href={regulationsSales(language)}
                      href={regulationSalesEn}
                      className="cart-summary-footer__link"
                    ></a>
                  ),
                }}
              ></Trans>
            }
          />
          <Components.BergButton
            color="primary"
            onClick={() => navigate('/cart/order-data')}
            label={t('pages:cart_data.go_to_ordering_data')}
          ></Components.BergButton>
        </Components.BergStack>
        <Components.BergStack
          direction="column"
          spacing={4}
          alignItems="end"
          className="cart-summary-footer"
        >
          <div className="cart-summary-footer__summary">
            <div className="order-summary-footer">
              <div className="order-summary-footer__item-header--bold">
                {t('components:order_summary_header.amount_label')}
              </div>
              <div className="order-summary-footer__item-description--bold">
                {basketTotal} {basketCurrency}
              </div>
            </div>
          </div>

          <Components.BergStack
            direction="column"
            spacing={4}
            justifyContent="flex-end"
            alignItems="end"
            alignSelf="end"
            className="cart-summary-footer__button"
          >
            {readRegulations ? (
              <Components.BergButton
                color="red"
                onClick={handleSubmit}
                label={t('pages:cart_data.go_to_payment')}
              ></Components.BergButton>
            ) : (
              <Tooltip content={t('pages:cart_data.approval_regulations')}>
                <Components.BergButton
                  color="gray-400"
                  onClick={handleSubmit}
                  label={t('pages:cart_data.go_to_payment')}
                  disabled={true}
                ></Components.BergButton>
              </Tooltip>
            )}
            <img
              src={require('../../../assets/images/payment.jpg')}
              alt={t('pages:cart_data.go_to_ordering_data')}
              className="cart-summary-footer__img"
            />
          </Components.BergStack>
        </Components.BergStack>
      </Components.BergStack>
    </BergPageContentWrapper>
  );
};
export default CartSummary;
