/* eslint-disable complexity */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icons from '../../assets/icons';
import './Footer.scss';
import MenuItems from '../Navigation/MenuItems';
import FooterItems from './FooterItems';
import Pictures from '../../assets/picture';
import siteSettings from '../../config/siteSettings';
import regulationSalesEn from '../../assets/regulations/en-internet-sales-terms-and-conditions.pdf';
import regulationSalesPl from '../../assets/regulations/pl-internet-sales-terms-and-conditions.pdf';
import regulationSalesSk from '../../assets/regulations/sk-internet-sales-terms-and-conditions.pdf';
import regulationTatraSuperSkiEn from '../../assets/regulations/en-regulation-of-tatra-super-ski-pass.pdf';
import regulationTatraSuperSkiPl from '../../assets/regulations/pl-regulation-of-tatra-super-ski-pass.pdf';
import regulationTatraSuperSkiSk from '../../assets/regulations/sk-regulation-of-tatra-super-ski-pass.pdf';
import rulesOfTheSkiSlopeSk from '../../assets/regulations/sk-rusels-of-conduct-on-ski-sklope.pdf';
import withdrawalFormSk from '../../assets/regulations/withdrawal-form-sk.pdf';
import termsAndConditionsSk from '../../assets/regulations/terms-and-conditions-sk.pdf';
import generalTermsAndConditionsSk from '../../assets/regulations/general-terms-and-conditions-sk.pdf';
import aquaparkRegulationsEn from '../../assets/regulations/sales_regulations_EN.pdf';
import i18n from '../../i18n';

const Footer = () => {
  const { t } = useTranslation('components');
  const [language] = useState(i18n.resolvedLanguage);

  const year = new Date().getFullYear();

  const facebook = siteSettings.facebookUrl;

  return (
    <section>
      <div className="footer-component-header">
        <img src={Pictures.LogoWhite} alt="logo-meander" />
        {facebook && (
          <a href={facebook}>
            <Icons.Fb className="footer-component-fb-logo-small-view" />
          </a>
        )}
        <div className="footer-component-header__partners">
          <div className="footer-component-line" />
          <p>{t('footer.partners')}</p>
          <a href="https://tatrysuperski.pl">{t('footer.tatry_super_ski')}</a>
        </div>
      </div>
      <div className="footer-component-list">
        <ul className="footer-component-navigation">
          <MenuItems />
          <FooterItems />
        </ul>
        {facebook && (
          <a href={facebook}>
            <Icons.Fb className="footer-component-navigation-fb-logo" />
          </a>
        )}
      </div>

      <div className="footer-component-company-data">
        <p>meander invest s.r.o.</p>
        <div>
          <p>M.R. Štefánika 1821</p>
          <p>026 01 Dolný Kubin</p>
          <p>IČO: 44820313</p>
          <p>TAX ID: SK2022842404</p>
        </div>
      </div>

      <hr></hr>
      <div className="footer-component-footer">
        <div className="footer-component-footer__rules">
          <div>
            <Link to="/privacy-policy">{t('footer.privacy')}</Link>
            <Link to="/cookies-policy">{t('footer.cookies')}</Link>
            {language === 'en' && (
              <a href={regulationSalesEn}>
                {t('footer.sales_terms_and_conditions')}
              </a>
            )}
            {language === 'pl' && (
              <a href={regulationSalesPl}>
                {t('footer.sales_terms_and_conditions')}
              </a>
            )}
            {language === 'sk' && (
              <a href={regulationSalesSk}>
                {t('footer.sales_terms_and_conditions')}
              </a>
            )}

            <a href={termsAndConditionsSk}>
              {t('footer.terms_and_conditions')}
            </a>
            <a href={generalTermsAndConditionsSk}>
              {t('footer.general_terms_and_conditions')}
            </a>
          </div>
          <div className="footer-component-line__rules" />
          <div>
            {language === 'en' && (
              <a href={regulationTatraSuperSkiEn}>
                {t('footer.skipass_regulation')}
              </a>
            )}
            {language === 'pl' && (
              <a href={regulationTatraSuperSkiPl}>
                {t('footer.skipass_regulation')}
              </a>
            )}
            {language === 'sk' && (
              <a href={regulationTatraSuperSkiSk}>
                {t('footer.skipass_regulation')}
              </a>
            )}

            <a href={rulesOfTheSkiSlopeSk}>
              {t('footer.rules_of_the_ski_slope')}
            </a>
            <a href={withdrawalFormSk}>{t('footer.withdrawal_form')}</a>

            <a href={aquaparkRegulationsEn}>
              {t('footer.aquapark_regulations')}
            </a>
          </div>
        </div>
        <div className="footer-component-footer__berg">
          <span className="footer-component-footer-copyright">
            {t('footer.copyright', { year })}
          </span>
          <a
            href="http://bergregions.pl"
            target="_blank"
            rel="noreferrer"
            className="footer-component-footer__berg-logo"
          >
            <Icons.LogoBergRegions />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
