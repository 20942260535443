import axios from 'axios';
import { initializeMockAdapter } from './axios-mock';
import i18n from '../i18n';
import snackbar from '../utils/snackbar';

const axiosInstance = axios.create({
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000 * 20,
  headers: { 'Accept-Language': i18n.resolvedLanguage },
  paramsSerializer: { indexes: null },
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.get['Content-Type'] = 'application/json';
axiosInstance.interceptors.response.use(undefined, (error) => {
  snackbar({
    type: 'error',
    msg: `Error ${error.response.status}`,
    id: error.response?.status,
  });
});

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_USE_MOCK_API === 'true'
) {
  console.log('Initializing axios mock adapter');
  initializeMockAdapter(axiosInstance);
}

export default axiosInstance;
