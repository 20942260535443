import { IAnnouncementsResponse } from '../../../modules/location/announcements/announcements.model';

export const announcementsResponseData = [
  {
    id: '1',
    type: 'WARNING',
    title: 'Alert RCB',
    text: 'Pred príchodom na lyžiarske stredisko skontrolujte, či lanovky a vleky súv prevádzke.',
  },
  {
    id: '2',
    type: 'INFORMATIONAL',
    title: 'Zagrożenie lawinowe',
    text: 'Dolorem nobis molestias rem recusandae.',
  },
  {
    id: '3',
    type: 'WARNING',
    title: 'Alert RCB',
    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum, ullam? Dolorem nobis molestias rem recusandae.',
  },
] as IAnnouncementsResponse[];
